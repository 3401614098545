.loading-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 5rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  gap: 1rem;
  background-color: #e0f7fa;
  border: 3px solid #00796b;
}

.loading-text {
  font-weight: 500;
  color: #00796b;
}

.unauthorized-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  text-align: center;
  color: #333;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.unauthorized-heading {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.logout-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #e91e62;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.admin-layout-container {
  min-height: 100vh;
  height: 100%;
  background-color: #ebebeb;
}
